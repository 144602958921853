<template>
  <div>
    <EmployeeArchivedTable
      :items="items"
      :is-busy="isBusyStore"
      :pagination="pagination"
      @changePage="handlePageChange"
    ></EmployeeArchivedTable>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import EmployeeArchivedTable from "@/modules/school/components/shared/employee/table/EmployeeArchivedTable";

export default {
  name: "TeacherArchivedPage",
  components: { EmployeeArchivedTable },
  mounted() {
    this.fetch();
  },
  methods: {
    fetch() {
      this.$store.dispatch("fetchTeacherArchivedList");
    },
    handlePageChange(page) {
      this.$store.commit("setTeacherArchivedListPage", page);
      this.fetch();
    },
  },
  computed: {
    ...mapGetters({
      items: "getTeacherArchivedList",
      pagination: "getTeacherArchivedPagination",
      isBusyStore: "getTeacherArchivedListBusy",
    }),
  },
};
</script>

<style scoped></style>
